import { LastAction } from './Info/LastAction';
import { ProgressBadge } from './Info/ProgressBadge';
import { ProgressList } from './Info/ProgressList/ProgressList';
import { Quickstart } from './Info/Quickstart';
import { TicketTeaser } from './Info/TicketTeaser';

export function SectionInfo() {
  return (
    <div className="px-5 flex flex-col gap-20">
      <Quickstart />

      <ProgressBadge />
      <TicketTeaser />
      <ProgressList />
      <LastAction />
    </div>
  );
}
