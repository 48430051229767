import { NavigationScope } from '@app/context/NavigationContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { Message } from '@components/block/Chat/Message';
import { NewBadge } from '@components/ui/NewBadge/NewBadge';
import { ChatWithUser, useGetChatQuery } from '@dieterApi/chat/useChatQuery';
import cx from 'classnames';
import { useEffect } from 'react';

interface Props {
  chat: ChatWithUser;
  collapsed: boolean;
  onClick: (id: string) => void;
}

export function AdminChatItem({ chat, collapsed, onClick }: Props) {
  const datetime = new Date(chat.createdAt).toLocaleString();
  const {
    navigation: { chatsViewed },
  } = useNavigation();

  const [getChat, { data }] = useGetChatQuery(chat.id);

  useEffect(() => {
    if (collapsed) return;
    getChat({ variables: { id: chat.id } });
  }, [collapsed]);

  const alreadyViewed = chatsViewed.includes(chat.id);

  const lengthNumber = Math.floor(chat.length / 2) * 100;
  const lengthColor = lengthNumber === 600 ? 'bg-primary-root' : `bg-primary-${lengthNumber}`;

  return (
    <div className="bg-white shadow-sm flex gap-2 items-center  hover:cursor-pointer" onClick={() => onClick(chat.id)}>
      <div className={cx('h-[60px] w-6 self-start', lengthColor)}></div>
      <div className="flex flex-col m-2 grow w-full">
        <div className="flex justify-between">
          <span className="">{chat.user.email || 'Anonymous'}</span>
          <ScopeBadge scope={chat.scope} />
        </div>
        <div className="flex gap-2 items-center">
          <span className="text-sm text-gray-600">{datetime}</span>
          {!alreadyViewed && <NewBadge />}
        </div>

        {!collapsed && (
          <div className="relative flex flex-col flex-grow overflow-y-auto mb-2 space-y-4 px-10 py-5">
            {data?.getChat[0].messages.map((message, i) => (
              <div
                key={i}
                className={`p-3 rounded-lg max-w-lg ${
                  message.role === 'assistant' ? 'bg-primary-100' : 'bg-secondary-100 ml-auto'
                }`}
              >
                <Message>{message.content}</Message>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="bg-primary-100 hidden"></div>
      <div className="bg-primary-200 hidden"></div>
      <div className="bg-primary-300 hidden"></div>
      <div className="bg-primary-400 hidden"></div>
      <div className="bg-primary-500 hidden"></div>

      <div className="bg-primary-700 hidden"></div>
      <div className="bg-primary-800 hidden"></div>
      <div className="bg-primary-900 hidden"> </div>
    </div>
  );
}

function ScopeBadge({ scope }: { scope: NavigationScope }) {
  const colors: Record<NavigationScope, string> = {
    dashboard: 'bg-blue-300 text-blue-700',
    quest: 'bg-green-300 text-green-700',
    quest_external: 'bg-green-300 text-green-700',
    onboarding: 'bg-yellow-300 text-yellow-700',
    audit: 'bg-red-300 text-red-700',
  };

  return <span className={cx('px-2 py-1 text-xs rounded-full', colors[scope])}>{scope}</span>;
}
