import { gql, useQuery } from '@apollo/client';

const GET_AUDIT_SUMMARY = gql`
  query GetAuditSummary($applicationId: String) {
    getAuditSummary(applicationId: $applicationId) {
      id
      createdAt
      updatedAt
      detailed
      condensed
    }
  }
`;

export interface GetAuditSummaryInput {
  applicationId?: string;
}

export interface AuditSummary {
  id: string;
  createdAt: string;
  updatedAt: string;
  detailed: string;
  condensed: string;
}

export interface GetAuditSummaryResult {
  getAuditSummary: AuditSummary;
}

export function useGetAuditSummaryQuery(applicationId?: string) {
  return useQuery<GetAuditSummaryResult, GetAuditSummaryInput>(GET_AUDIT_SUMMARY, {
    variables: { applicationId },
    // pollInterval: 1000,
  });
}
