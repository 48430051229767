export interface DocumentShareToken {
  localQuestionnaireId: string;
  type: 'Document' | 'Questionnaire' | 'ConsentForm' | 'UniqueQuestionnaire';
  documentNumber: number;
  documentTitle: string;
  questionnaireId?: string;
  applicationId?: string;
  companyName: string;
  withConfirmation: boolean;
  consentFrom?: 'Bewerber' | 'Mitarbeiter' | 'Vertragspartner';
  userValuesJson?: string;
  whitelabelConfigJson?: string;
}

export interface FileShareToken {
  type: 'download' | 'upload';
  companyName: string;
  description?: string;
  fileId?: string;
  fileName?: string;
  topicId?: string;
  userValueIndex?: string;
}

type TokenTypes = DocumentShareToken | FileShareToken;

export function parseJwt<T = TokenTypes>(token: string): T | undefined {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload: string;
  try {
    jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  } catch (error) {
    console.error('Invalid token');
    return undefined;
  }

  return JSON.parse(jsonPayload);
}
