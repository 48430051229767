import { Provider as BalancerProvider } from 'react-wrap-balancer';

import { ApolloProvider } from '@apollo/client';
// import client from '@app/client.ts';
import { useGetShareTokenJWTQuery } from '@dieterApi/sharetoken/useGetShareTokenJWTQuery';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import Values from 'values.js';
import { useApolloClient } from './context/ApolloClientContext';
import { FlowProvider } from './context/FlowContext';
import { MessageProvider } from './context/MessageContext';
import { MessageQueueProvider } from './context/MessageQueueContext';
import { NavigationProvider } from './context/NavigationContext';
import { OrderProvider } from './context/OrderContext';
import { TaskProvider } from './context/TaskContext';
import { UserProvider, useUser } from './context/UserContext';
import { useQueryParams } from './hooks/useQuestionaireRoute';
import { DocumentShareToken, parseJwt } from './utils/parseJwt';

const makeTheme = (primaryColor: string, secondaryColor: string) =>
  createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      success: {
        main: '#91ab46;',
      },
    },
    typography: {
      fontFamily: ['DieterRubik'].join(','),
    },
    zIndex: {
      snackbar: 10000,
    },
  });

interface IProps {
  children: ReactNode;
}

function AppProvider({ children }: IProps) {
  const { client } = useApolloClient();
  if (!client) return null;
  return (
    <ApolloProvider client={client}>
      <SnackbarProvider maxSnack={5} classes={{ containerRoot: 'z-alert' }}>
        <UserProvider>
          <ThemeWrapper>
            <NavigationProvider>
              <MessageQueueProvider>
                <MessageProvider>
                  <TaskProvider>
                    <FlowProvider>
                      <OrderProvider>
                        <BalancerProvider>{children}</BalancerProvider>
                      </OrderProvider>
                    </FlowProvider>
                  </TaskProvider>
                </MessageProvider>
              </MessageQueueProvider>
            </NavigationProvider>
          </ThemeWrapper>
        </UserProvider>
      </SnackbarProvider>
    </ApolloProvider>
  );
}

// ThemeWrapper wraps around MUIs ThemeProvider to add dynamic color capabilities.
function ThemeWrapper({ children }: IProps) {
  const { user } = useUser();

  const query = useQueryParams();

  const token = query.get('token') || '';

  const { data: shareTokenJWT, loading } = useGetShareTokenJWTQuery({
    variables: { id: token },
  });

  const jwt = shareTokenJWT?.getShareTokenJWT?.jwt;
  const tokenInfo = jwt ? parseJwt<DocumentShareToken>(jwt)! : undefined;
  const whitelabelConfig = tokenInfo?.whitelabelConfigJson ? JSON.parse(tokenInfo.whitelabelConfigJson) : null;

  const [theme, setTheme] = useState(makeTheme('#ab5fce', '#fdd213'));

  useEffect(() => {
    applyDieterColors();
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!user && !whitelabelConfig) {
      applyDieterColors();
      return;
    }
    if (whitelabelConfig) {
      const primaryColor = whitelabelConfig.primaryColor;
      const secondaryColor = whitelabelConfig.secondaryColor;
      setTheme(makeTheme(primaryColor, secondaryColor));
      applyCustomTheme(primaryColor, secondaryColor);
      return;
    }
    if (!user) return;
    const principalCompany = user.companies.find((company) => company.isPrincipal === true);
    if (!principalCompany) return;
    if (!principalCompany?.features.includes('Whitelabel')) return;
    if (principalCompany?.whitelabelConfig) {
      const primaryColor = principalCompany.whitelabelConfig.primaryColor;
      const secondaryColor = principalCompany.whitelabelConfig.secondaryColor;
      setTheme(makeTheme(primaryColor, secondaryColor));
      applyCustomTheme(primaryColor, secondaryColor);
      return;
    }
  }, [user, whitelabelConfig?.primaryColor, whitelabelConfig?.secondaryColor]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default AppProvider;

const applyCustomTheme = (primaryColor: string, secondaryColor: string) => {
  const tintSteps = ['50', '100', '200', '300', '400'];
  const shadeSteps = ['600', '700', '800', '900'];

  // Primary color
  let contrast = '18';
  for (let i = 0; i < tintSteps.length; i++) {
    const color = '#' + new Values(primaryColor).tint((tintSteps.length - i) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--primary-${tintSteps[i]}`, color);
  }
  document.documentElement.style.setProperty('--primary-root', primaryColor);
  for (let i = 0; i < shadeSteps.length; i++) {
    const color = '#' + new Values(primaryColor).shade((i + 1) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--primary-${shadeSteps[i]}`, color);
  }
  document.documentElement.style.setProperty('--primary-root-t20', primaryColor + '33');

  // Secondary color
  contrast = '12';
  for (let i = 0; i < tintSteps.length; i++) {
    const color = '#' + new Values(secondaryColor).tint((tintSteps.length - i) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--secondary-${tintSteps[i]}`, color);
  }
  document.documentElement.style.setProperty('--secondary-root', secondaryColor);
  for (let i = 0; i < shadeSteps.length; i++) {
    const color = '#' + new Values(secondaryColor).shade((i + 1) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--secondary-${shadeSteps[i]}`, color);
  }
  document.documentElement.style.setProperty('--secondary-root-t20', secondaryColor + '33');
};

const applyDieterColors = () => {
  const setProp = (prop: string, color: string) => {
    document.documentElement.style.setProperty(prop, color);
  };
  setProp('--primary-50', '#f5ecfb');
  setProp('--primary-100', '#eddcf8');
  setProp('--primary-200', '#e0c1f1');
  setProp('--primary-300', '#cc99e7');
  setProp('--primary-400', '#b871db');
  setProp('--primary-root', '#ab5fce');
  setProp('--primary-600', '#8e40b0');
  setProp('--primary-700', '#773891');
  setProp('--primary-800', '#612f74');
  setProp('--primary-900', '#612f74');
  setProp('--secondary-50', '#fefce8');
  setProp('--secondary-100', '#fefce8');
  setProp('--secondary-200', '#fffbc2');
  setProp('--secondary-300', '#fff388');
  setProp('--secondary-400', '#ffe545');
  setProp('--secondary-root', '#fdd213');
  setProp('--secondary-600', '#edb905');
  setProp('--secondary-700', '#cd8e01');
  setProp('--secondary-800', '#a36505');
  setProp('--secondary-900', '#874f0c');
};
