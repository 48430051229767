import LangSelect from '@components/block/Header/LangSelect.tsx';
import { Logo } from '@components/block/Header/Logo';
import AccountMenuItems from '@components/ui/AccountMenu/AccountMenuItems.tsx';
import TenantMenu from '@components/ui/TenantMenu/TenantMenu.tsx';
import { UserRoleChip } from '@components/ui/UserRoleChip/UserRoleChip.tsx';
import { useGetBillingPortalSessionQuery } from '@dieterApi/stripe/useGetBillingPortalSessionQuery.ts';
import { User } from '@dieterApi/user/useUserQuery.ts';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Drawer, IconButton, MenuList } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import { useState } from 'react';

interface IProps {
  onClose?(): void;
  open?: boolean;
  user?: User | null;
  stringAvatar?: string;
}
function AccountDrawerMenu({ open, onClose, user, stringAvatar }: IProps) {
  const [getPortalSession, { data, loading }] = useGetBillingPortalSessionQuery();
  const portalUrl = data?.getBillingPortalSession;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  React.useEffect(() => {
    if (open) {
      getPortalSession();
    }
  }, [open]);

  const userName = user?.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user?.email || 'N N';

  const handleMultiTenancyClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTenantMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          zIndex: 9999,
          width: '100vw',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100vw',
            boxSizing: 'border-box',
          },
        }}
      >
        <div className="dtHeader px-2 flex items-center justify-between text-white">
          <div className="dtHeader__brand ml-4">
            <Logo clickable={false} />
          </div>
          <IconButton aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <MenuList>
          {!(!user || user.isAnonymous) && (
            <div>
              <div className="flex items-center gap-2 p-4">
                {!user || user?.isAnonymous ? <InfoIcon /> : <Avatar>{stringAvatar}</Avatar>}
                <div className="px-4 flex flex-col gap-2">
                  <div
                    className="text-primary-root flex items-center gap-2"
                    onClick={(evt) => {
                      if (user.hasFeature('MultiTenancy')) handleMultiTenancyClick(evt);
                    }}
                  >
                    {userName}
                    {user.hasFeature('MultiTenancy') && (
                      <div className="text-xs border-[1px] border-gray-300 rounded-sm hover:bg-primary-100 hover:text-primary-root transition-colors">
                        <KeyboardArrowDownIcon fontSize="inherit" />
                      </div>
                    )}
                  </div>{' '}
                  <div className="flex items-center gap-2 text-sm">
                    <span className="text-gray-500">Rolle: </span>
                    <UserRoleChip role={user?.role} />
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          )}
          <AccountMenuItems user={user} onClose={onClose} portalUrl={portalUrl} loading={loading} />
        </MenuList>
        <div className="mt-auto px-4 flex items-center justify-between h-12 bg-gray-50 border-t">
          <LangSelect inHeader={false} />
        </div>
      </Drawer>
      <TenantMenu anchorEl={anchorEl} handleClose={handleCloseTenantMenu} user={user} />
    </>
  );
}

export default AccountDrawerMenu;
