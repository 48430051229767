import { useUser } from '@app/context/UserContext';
import React, { ReactNode, useEffect, useRef } from 'react';

export const TextReplacer: React.FC<{ children: ReactNode }> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { user } = useUser();

  useEffect(() => {
    function replaceText(node: ChildNode | null): void {
      if (node && node.nodeType === Node.TEXT_NODE && node.textContent) {
        node.textContent = node.textContent.replace(/Dieter/g, 'Datenschutzexperte');
      } else if (node) {
        node.childNodes.forEach((child) => replaceText(child));
      }
    }

    if (ref.current && user?.hasFeature('Whitelabel')) {
      replaceText(ref.current);
    }
  });

  return <div ref={ref}>{children}</div>;
};
