import { useNavigation } from '@app/hooks/useNavigation';
import { Chat } from '@components/block/Chat/Chat';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AuditSummary } from './AuditSummary';

export function AuditAgent() {
  const { setNavigation, navigation } = useNavigation();
  const {
    params: { applicationId },
  } = useRouteMatch<{ applicationId?: string }>();

  useEffect(() => {
    setNavigation((nav) => void (nav.scope = 'audit'));
  }, [navigation]);

  return (
    <div className="2xl:container md:px-7 mb-24">
      <h1>Audit Prototype</h1>
      {applicationId && <h2>Application ID: {applicationId}</h2>}
      {!applicationId ? (
        <ul className="list-disc">
          <li>
            <a href="/audit/app-dse-privacy-policy-create">Datenschutzerklärung</a>
          </li>
          <li>
            <a href="/audit/app-vvz-create">Verarbeitungsverzeichnis</a>
          </li>
        </ul>
      ) : (
        <>
          <Chat />
          <AuditSummary />
        </>
      )}
    </div>
  );
}
