import { gql, useLazyQuery } from '@apollo/client';

export const GET_QUESTIONNAIRE_SHARE = gql`
  query GetQuestionnaireShareToken(
    $localQuestionnaireId: String
    $applicationId: String
    $type: ShareTokenType!
    $documentNumber: Int
    $withConfirmation: Boolean
    $consentFrom: ConsentFrom
    $expiresIn: String
    $forCompanyId: String
    $userValuesJson: String
  ) {
    getQuestionnaireShareToken(
      questionnaireId: $localQuestionnaireId
      applicationId: $applicationId
      type: $type
      consentFrom: $consentFrom
      documentNumber: $documentNumber
      withConfirmation: $withConfirmation
      expiresIn: $expiresIn
      forCompanyId: $forCompanyId
      userValuesJson: $userValuesJson
    )
  }
`;

export interface GetQuestionnaireShareResult {
  getQuestionnaireShareToken: string;
}

export type ShareTypes = 'Document' | 'Questionnaire' | 'ConsentForm' | 'UniqueQuestionnaire';
export type ConsentFrom = 'Bewerber' | 'Mitarbeiter' | 'Vertragspartner';

export interface GetQuestionnaireShareInput {
  localQuestionnaireId?: string;
  applicationId?: string;
  type: ShareTypes;
  consentFrom?: ConsentFrom;
  documentNumber?: number;
  withConfirmation?: boolean;
  expiresIn?: string;
  forCompanyId?: string;
  userValuesJson?: string; // json encoded string
}

export function useQuestionnaireDocumentShareQuery() {
  return useLazyQuery<GetQuestionnaireShareResult, GetQuestionnaireShareInput>(GET_QUESTIONNAIRE_SHARE, {
    fetchPolicy: 'no-cache',
  });
}
