import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Collapse, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

interface FeatureSegment {
  id: string;
  title: string;
  rows: FeatureRow[];
}

interface FeatureRow {
  name: string;
  availability: string[];
  tooltip?: string;
}

const features: FeatureSegment[] = [
  {
    title: 'Datenschutzmanagement und -prozesse',
    id: 'datenschutzmanagement-und-prozesse',
    rows: [
      { name: 'Allgemeine Hinweise zur DSGVO', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      {
        name: 'Nutzung des integrierten Datenschutz-Management-System',
        availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'],
      },
      {
        name: 'DSGVO-konforme Datenschutzerklärung',
        availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'],
      },
      // { name: 'DSGVO-Website-Scanner', availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      {
        name: 'Datenschutz-Folgenabschätzungen inkl. Risikoanalyse',
        availability: ['', '', '', '✓', '✓', '', '✓', '✓'],
      },
      { name: 'Unterstützung bei Datenschutz-Folgenabschätzungen', availability: ['', '', '', '✓', '✓', '', '✓', '✓'] },
      { name: 'Erstellung eines Löschkonzeptes', availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Erstellung der gesamten DSGVO-Dokumentation', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Verarbeitungsverzeichnis & Löschkonzept', availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Verwaltung bestehender Dokumente', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Beantwortung von Betroffenenanfragen', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Anzahl User mit Rechteverwaltung', availability: ['1', '1', '1', '3', '10', '3', '10', '5'] },
      // {
      //   name: 'Anzahl Website-Scans',
      //   availability: ['-2', '0', '2', '5', '10', '1/Mandant', '2/Mandant', '2/Mandant'],
      // },
      {
        name: 'Anzahl absicherbarer Webseiten',
        availability: ['1', '', '1', '2', '5', '1/Mandant', '2/Mandant', '2/Mandant'],
      },
      { name: 'Automatische Updates', availability: ['✓', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      {
        name: 'Automatisierte Anpassungen an rechtliche Änderungen',
        availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'],
      },
      { name: 'DSGVO Abmahnschutz', availability: ['✓', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Datenschutzanfragen / Datenpannen', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Beantwortung von Betroffenenanfragen', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      {
        name: 'DSGVO-konformer Umgang mit Datenschutzverletzungen',
        availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'],
      },
      { name: 'Rechtssichere Einwilligungen erstellen', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Hosting auf DE-Servern', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'White-Label-Lösung', availability: ['', '', '', '', '', 'auf Anfrage', 'auf Anfrage', 'auf Anfrage'] },
      {
        name: 'Generator für Technische und organisatorische Maßnahmen (TOMs)',
        availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'],
      },
      {
        name: 'Generator für Auftragsverarbeitung (AV-Vertrag)',
        availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'],
      },
      { name: 'Video-Überwachung', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
    ],
  },
  {
    id: 'website-und-online-datenschutz',
    title: 'Website- und Online-Datenschutz',
    rows: [
      { name: 'Integration per Live-API', availability: ['✓', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      // { name: 'Cookie Consent Tool (20.000 Sessions/Webprojekt)', availability: ['', '', '', '', '', '✓', '✓', ''] },
      { name: 'Datenschutzerklärung-Generator', availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Datenschutzerklärung für Social Media', availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Impressums-Generator', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      {
        name: 'Umfassende Cookie-Datenbank, Drittanbietern und externen Diensten',
        availability: ['(✓)', '', '✓', '✓', '✓', '✓', '✓', '✓'],
      },
    ],
  },
  {
    id: 'tools-und-plattformen',
    title: 'Tools und Plattformen',
    rows: [
      { name: 'Datenschutz Score', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Datenbank zur Speicherung von Dokumenten', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Datenschutz E-Learning Plattform', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Rechtstexte Plugin für CMS', availability: ['✓', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Online Zugriff auf Ihre Dokumentationen', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Praxisorientiertes Taskmanagement', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Mandantenverwaltung im Dashboard', availability: ['', '', '', '', '', '✓', '✓', '✓'] },
      { name: 'Multi-User-Fähigkeit', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
    ],
  },
  {
    id: 'datenschutz-fuer-mitarbeiter',
    title: 'Datenschutz für Mitarbeiter',
    rows: [
      {
        name: 'Datenschutzrechtliche Grundschulung (Anzahl Nutzer/Mitarbeiter)',
        availability: ['1', '1', '10', '30', '50', '10', '30', '30'],
      },
      { name: 'Rechtssichere Einwilligungen verwalten', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'DSGVO-Verpflichtung', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Homeoffice', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Bewerbung & Anstellung', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
    ],
  },
  {
    id: 'umfassende-beratung',
    title: 'Umfassende Beratung',
    rows: [
      { name: 'Persönliche Beratung durch Datenschutzexperten', availability: ['', '', '', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Praxisorientierte Handlungsempfehlungen', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Umfassende DSGVO-Analyse des Unternehmens', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
    ],
  },
  {
    id: 'projekt-und-mandantenmanagement',
    title: 'Projekt- und Mandantenmanagement',
    rows: [
      { name: 'Abkopplung der Mandantenaccounts nach Projektende', availability: ['', '', '', '', '', '✓', '✓', '✓'] },
      { name: 'Projekte/Mandantenverwaltung', availability: ['', '', '', '', '', '✓', '✓', '✓'] },
    ],
  },
  {
    id: 'kundenservice-und-support',
    title: 'Kundenservice und Support',
    rows: [
      { name: 'FAQ-Sammlung', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Mail Kundensupport (Mo-Fr)', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Telefonischer Kundensupport (Mo-Fr)', availability: ['', '', '', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Live-Service per Chat', availability: ['✓', '✓', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Notfall-Support', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] },
      { name: 'Fester interner Kontakt und priorisierte Bearbeitung', availability: ['', '', '', '', '✓', '', '✓'] },
    ],
  },
  {
    id: 'zusammenarbeit-mit-behoerden',
    title: 'Zusammenarbeit mit Behörden',
    rows: [
      {
        name: 'Bestellung eines TÜV-zertifizierten Datenschutzbeauftragten (DSB)',
        availability: ['', '', '', '✓', '✓', '', '✓'],
      },
      { name: 'Zusammenarbeit mit der zuständigen Aufsichtsbehörde', availability: ['', '', '', '✓', '✓', '', '✓'] },
    ],
  },
  {
    id: 'zertifizierungen-und-siegel',
    title: 'Zertifizierungen und Siegel',
    rows: [{ name: 'Siegel für Webseite & Kundenseiten', availability: ['', '', '✓', '✓', '✓', '✓', '✓', '✓'] }],
  },
];

export function FeatureTable() {
  const [expandedSegments, setExpandedSegments] = useState<number[]>([]);
  const hash = window.location.hash.substring(1); // Get the hash without the `#`

  // Function to handle opening the accordion and updating the URL hash
  const toggleSegment = (index: number, segmentId: string) => {
    setExpandedSegments((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
    if (!expandedSegments.includes(index)) {
      window.history.replaceState(null, '', `#${segmentId}`);
    }
  };

  // Scroll to the header based on the URL hash
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        console.log('Scrolling to', hash);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // Expand the accordion corresponding to the hash
        const index = features.findIndex((segment) => segment.title.toLowerCase().replace(/\s+/g, '-') === hash);
        if (index !== -1) setExpandedSegments((prev) => [...prev, index]);
      }
    }
  }, [hash]);

  return (
    <div id="dieter-feature-table" className="mb-80">
      <div className="text-center mb-4">
        {/* Header */}
        <span className="text-3xl font-normal">Vergleiche die Funktionen der Pakete</span>
      </div>
      <div>
        {features.map((segment, index) => (
          <div key={index} className="border-b">
            {/* Accordion Header */}
            <button
              id={segment.id} // Assign ID for anchor navigation
              onClick={() => toggleSegment(index, segment.id)}
              className="w-full text-left px-4 py-3 bg-gray-100 hover:bg-primary-200 flex justify-between items-center"
            >
              <span className="font-semibold">{segment.title}</span>
              <svg
                className={`w-6 h-6 transition-transform ${expandedSegments.includes(index) ? 'rotate-180' : ''}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            {/* Accordion Content */}
            {
              <Collapse in={expandedSegments.includes(index)}>
                <div className="px-4 py-2 bg-white">
                  {/* Desktop Table */}
                  <div className="hidden md:block">
                    <table className="w-full table-fixed">
                      <thead>
                        <tr>
                          <th className="w-2/5 text-left py-2"></th>
                          <th className="text-center">Einzel&shy;leistung</th>
                          <th className="text-center">Freemium</th>
                          <th className="text-center">Basic</th>
                          <th className="text-center">Premium</th>
                          <th className="text-center">Premium +</th>
                          <th className="text-center">Agency Basic</th>
                          <th className="text-center">Agency Premium</th>
                          <th className="text-center">DSB</th>
                        </tr>
                      </thead>
                      <tbody>
                        {segment.rows.map((feature, i) => (
                          <tr key={i} className="border-t">
                            <td className="text-left py-2">
                              {feature.name}
                              {feature.tooltip && (
                                <Tooltip title={feature.tooltip}>
                                  <HelpOutlineIcon className="text-gray-400" fontSize="small" />
                                </Tooltip>
                              )}
                            </td>
                            {feature.availability.map((availability, j) => (
                              <td key={j} className="text-center py-2">
                                <Tooltip
                                  title={availability === '(✓)' ? 'abhängig von gewählter Einzelleistung' : undefined}
                                >
                                  <span>{availability}</span>
                                </Tooltip>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Mobile Cards */}
                  <div className="block md:hidden">
                    {segment.rows.map((feature, i) => (
                      <div key={i} className="border rounded-lg p-4 mb-4 shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">{feature.name}</h4>
                        <div className="space-y-2">
                          {feature.availability.map((availability, j) => (
                            <div key={j} className="flex justify-between items-center border-b pb-1 last:border-0">
                              <span className="font-normal">
                                {
                                  [
                                    'Freemium',
                                    'Einzelleistung',
                                    'Basic',
                                    'Premium',
                                    'Premium +',
                                    'Agency Basic',
                                    'Agency Premium',
                                    'DSB',
                                  ][j]
                                }
                              </span>
                              <span>{availability}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
            }
          </div>
        ))}
      </div>
    </div>
  );
}
