import { Markdown } from '@components/ui';
import { useGetAuditSummaryQuery } from '@dieterApi/audit/useAuditSummaryQuery';
import cx from 'classnames';
import { useRouteMatch } from 'react-router-dom';
export function AuditSummary() {
  const {
    params: { applicationId },
  } = useRouteMatch<{ applicationId?: string }>();

  const { data, loading, error } = useGetAuditSummaryQuery(applicationId);

  return (
    <div
      className={cx(
        'max-h-[95vh] max-w-[600px] fixed z-[1240] overflow-hidden rounded-t-2xl md:shadow-[0px_2px_6px_-1px_rgba(0,0,0,0.2)]',
        'w-full',
        'bg-white z-[1000000]',
        'bottom-0 right-0 '
      )}
    >
      <div className={'h-[80vh] p-8'}>
        <div className="text-lg font-medium mb-4">Audit Summary: {applicationId}</div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="flex flex-col gap-8">
            <div>
              <p className="font-bold">Detailed:</p>
              <Markdown
                className=""
                text={data?.getAuditSummary?.detailed || ''}
              />
            </div>
            <div>
              <p className="font-bold">Condensed:</p>
              <Markdown
                className=""
                text={data?.getAuditSummary?.condensed || ''}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
