import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import SideMenuList from '@app/routes/Dashboard/SideMenuList.tsx';
import LangSelect from '@components/block/Header/LangSelect';
import AccountDrawerMenu from '@components/ui/AccountMenu/AccountDrawerMenu.tsx';
import AccountMenu from '@components/ui/AccountMenu/AccountMenu';
import TenantMenu from '@components/ui/TenantMenu/TenantMenu';
import { useUserLogoutMutation } from '@dieterApi/user/useUserLogoutMutation';
import { ArrowCircleUp, ConfirmationNumber, Stars, WorkspacePremium } from '@mui/icons-material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, IconButton, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { animated, useSpring } from 'react-spring';
import './header.sass';
import { Logo } from './Logo';
import './notifications.sass';

export function Header() {
  const { user, userLoading } = useUser();
  const history = useHistory();
  const { t } = useTranslation();

  // Only display the header once the user is loaded, otherwise we risk a flash of unstyled color for whitelabel users.
  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    if (user || !userLoading) setShowHeader(true);
  });

  const [logout] = useUserLogoutMutation();

  const { navigation, setNavigation } = useNavigation();
  const { itemListOpen, isMobile } = navigation;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);

  const [animateCompanyName, setAnimateCompanyName] = useState(false);

  const isExternal = navigation.scope === 'quest_external';
  const isOnboardingScope = navigation.scope === 'onboarding';

  const animate = useSpring({
    to: {
      color: animateCompanyName ? '#000' : '#fff',
    },
    onRest: () => setAnimateCompanyName(false),
  });

  useEffect(() => {
    // on logout close drawers
    if (!user) {
      setAnchorEl(null);
      setAnchorEl2(null);
    }
  }, [user]);

  useEffect(() => {
    if (user?.company.name) {
      setAnimateCompanyName(true);
    }
  }, [user?.company.name]);

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMultiTenancyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const userName = user?.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user?.email || 'N N';
  const handleCloseSideMenu = () => setNavigation((nav) => void (nav.itemListOpen = !nav.itemListOpen));

  if (!user && !navigation.isDieterHost) return null;

  return (
    <>
      <header
        className={cx('dtHeader min-h-[80px] items-center flex', {
          'hide-header': navigation.isPrintView,
          invisible: !showHeader, // invisible remains in the DOM, but does not render.
        })}
      >
        {/* DESKTOP HEADER */}
        <div className={cx('w-full hidden md:block')}>
          <div className="2xl:container flex gap-20 justify-between mx-auto px-9">
            <div
              className={cx(
                'dtHeader__brand flex-none md:flex md:gap-20 md:flex-2 md:relative md:translate-x-0 md:left-0 absolute -translate-x-1/2 left-1/2',
                !isExternal && !isOnboardingScope && 'cursor-pointer'
              )}
            >
              <Logo />
            </div>
            <div className="flex-7 hidden md:block" />
            <div className="dtHeader__user flex gap-5 md:gap-8 flex-1 items-center justify-end">
              {/* LANGUAGE BUTTON */}
              <LangSelect />
              {user && (
                <>
                  {user.company.name && (
                    // Display active Tenant
                    <div className="flex items-center gap-2">
                      <animated.div style={animate} className="hidden md:block">
                        {user.company.name}
                      </animated.div>
                      {user.companies.length > 1 && (
                        <button
                          onClick={handleMultiTenancyClick}
                          className="text-xs border-[1px] border-gray-300 rounded-sm hover:bg-primary-100 hover:text-primary-root transition-colors"
                        >
                          <KeyboardArrowDownIcon fontSize="inherit" />
                        </button>
                      )}
                    </div>
                  )}
                  {/* // SUBSCRIPTION BADGE */}
                  {user.subscription ? (
                    <div className="flex flex-col w-full items-center gap-3">
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        title={
                          user.subscription === 'Ticket' ? (
                            <div className="flex flex-col gap-1">
                              <Trans t={t} i18nKey="layout.global.header.subscription_badge">
                                <strong className="font-bold">Freigeschaltete Bereiche:</strong>
                                <ul
                                  // @ts-expect-error
                                  i18nIsDynamicList
                                >
                                  {user.company.topicsActivated.map((topicId, index) => (
                                    <li key={index}>{user?.topics?.find((t) => t.id === topicId)?.title}</li>
                                  ))}
                                </ul>
                              </Trans>
                            </div>
                          ) : undefined
                        }
                        placement="bottom"
                      >
                        <button
                          className="dtHeader__subscriptiontag hidden md:flex flex-col items-center group relative hover:outline hover:outline-white hover:outline-2 min-w-[100px]"
                          onClick={() => history.push('/pricing')}
                        >
                          <div className="group-hover:text-black flex items-center gap-[2px]">
                            <span>{user?.company.subscription}</span>
                            <span>{user?.company.isPlus && '+'}</span>
                            <span>{user.isTrialing && 'Test'}</span>
                            <span>{user?.company.isDpo && t('layout.global.header.dpo_badge')}</span>
                            {user?.company.isAgency && t('layout.global.header.agency_badge')}
                            {user?.company.subscription === 'Ticket' && <ConfirmationNumber fontSize="small" />}
                            {user?.company.subscription === 'Premium' && <WorkspacePremium fontSize="small" />}
                            {user?.company.subscription === 'Basic' && <Stars fontSize="small" />}
                          </div>
                          <div className="hidden group-hover:flex absolute left-1/2 -translate-x-1/2  items-center gap-1">
                            <span>Upgrade</span>
                            <ArrowCircleUp fontSize="small" />
                          </div>
                        </button>
                      </Tooltip>
                      {/* <button className="text-sm underline hover:text-primary-root hover:bg-white transition-all duration-300 px-2 rounded-lg">
                        Upgrade
                      </button> */}
                    </div>
                  ) : (
                    /* // Pricing Link */
                    ['dashboard'].includes(navigation.scope || '') && (
                      <button
                        className="border-white border-1 rounded-md px-2 py-1 hover:bg-white hover:text-primary-root text-sm transition duration-300 ease-in-out"
                        onClick={() => history.push('/pricing')}
                        data-testid="button-pricing"
                      >
                        <div className="flex gap-1 items-center">
                          {/* <OpenInNewIcon fontSize="inherit" /> */}
                          {t('layout.global.header.pricing_link', 'Preise')}
                        </div>
                      </button>
                    )
                  )}

                  {/* // ADMIN BUTTON */}
                  {user.isAdmin && (
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      title={t('layout.global.header.admin_link', 'Administration')}
                    >
                      <button
                        className="dtHeader__button self-center hidden md:block"
                        onClick={() => history.push('/admin')}
                        data-testid="admin-button"
                      >
                        <AdminPanelSettingsOutlinedIcon />
                      </button>
                    </Tooltip>
                  )}
                  {/* // NOTIFICATION BUTTON */}
                  {/* {!user.isAnonymous && user.roqToken && (
                <Tooltip title="Benachrichtigungen">
                  <div>
                    <NotificationBell icon={<NotificationsNoneIcon />} />
                  </div>
                </Tooltip>
              )} */}

                  {/* ACCOUNT BUTTON */}
                </>
              )}
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                  !user || user?.isAnonymous ? 'Info' : t('layout.global.header.account_dropdown_button', 'Account')
                }
                placement="bottom"
              >
                <button className="dtHeader__button" onClick={handleAccountClick} data-testid="account-button">
                  {!user || user?.isAnonymous ? <InfoIcon /> : <Avatar {...stringAvatar(userName)} />}
                </button>
              </Tooltip>
              {user?.isAnonymous && (
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={t('common.action.login', 'Einloggen')}>
                  <button className="dtHeader__button self-center" onClick={() => logout()}>
                    <LoginRoundedIcon />
                    {/* {<Icon type={user.isAnonymous ? 'login-36' : 'logout-36'} scale={0.9} size={24} /> } */}
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {/* MOBILE HEADER */}
        <div className={cx('flex items-center justify-between px-3 w-full md:hidden', { hidden: !isMobile })}>
          {user && !user?.isAnonymous ? (
            <IconButton
              style={{ color: 'white' }}
              aria-label="menu-toggle"
              size="small"
              data-testid="nav-toggle-mobile"
              onClick={() => setNavigation((nav) => void (nav.itemListOpen = !nav.itemListOpen))}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          ) : (
            <LangSelect />
          )}
          <div
            className={cx(
              'dtHeader__brand flex-none md:flex md:gap-20 md:flex-2 md:relative md:translate-x-0 md:left-0 absolute -translate-x-1/2 left-1/2',
              !isExternal && !isOnboardingScope && 'cursor-pointer'
            )}
          >
            <Logo />
          </div>
          <div className="flex gap-4 ml-auto">
            <Tooltip
              title={!user || user?.isAnonymous ? 'Info' : t('layout.global.header.account_dropdown_button', 'Account')}
              placement="bottom"
            >
              <button className="dtHeader__button" onClick={handleAccountClick} data-testid="account-button-mobile">
                {!user || user?.isAnonymous ? <InfoIcon /> : <Avatar {...stringAvatar(userName)} />}
              </button>
            </Tooltip>
            {user?.isAnonymous && (
              <Tooltip title={t('common.action.login', 'Einloggen')}>
                <button className="dtHeader__button self-center" onClick={() => logout()}>
                  <LoginRoundedIcon />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </header>
      {isMobile ? (
        <>
          <AccountDrawerMenu
            open={!!anchorEl}
            onClose={handleClose}
            user={user}
            stringAvatar={stringAvatar(userName).children}
          />
          <Drawer
            anchor="left"
            open={itemListOpen}
            onClose={handleCloseSideMenu}
            sx={{
              zIndex: 99999,
              width: '80vw',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: '80vw',
                boxSizing: 'border-box',
              },
            }}
          >
            <div className="dtHeader px-2 flex items-center justify-between text-white">
              <div className="dtHeader__brand ml-4">
                <Logo clickable={false} />
              </div>
              <IconButton aria-label="close" color="inherit" onClick={handleCloseSideMenu}>
                <CloseIcon />
              </IconButton>
            </div>
            <SideMenuList />
          </Drawer>
        </>
      ) : (
        <AccountMenu anchorEl={anchorEl} handleClose={handleClose} user={user} />
      )}
      <TenantMenu anchorEl={anchorEl2} handleClose={handleClose} user={user} />
    </>
  );
}

function stringAvatar(name: string) {
  const splitString = name.includes('.') ? '.' : name.includes('-') ? '-' : name.includes('@') ? '@' : ' ';
  const nameSplit = name.toUpperCase().split(splitString);
  return {
    children: `${nameSplit[0][0]}`, //${nameSplit[1][0]}
  };
}
