import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { useQueryParams } from '@app/hooks/useQuestionaireRoute';
import { DocumentShareToken, parseJwt } from '@app/utils/parseJwt';
import { useGetShareTokenJWTQuery } from '@dieterApi/sharetoken/useGetShareTokenJWTQuery';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export function Logo({ clickable = true }: { clickable?: boolean }) {
  const history = useHistory();
  const { user } = useUser();
  const { navigation } = useNavigation();

  const query = useQueryParams();

  const token = query.get('token') || '';
  const { data: shareTokenJWT, loading } = useGetShareTokenJWTQuery({
    variables: { id: token },
  });

  const jwt = shareTokenJWT?.getShareTokenJWT?.jwt;
  const tokenInfo = jwt ? parseJwt<DocumentShareToken>(jwt)! : undefined;

  const [logoB64, setLogoB64] = useState<string | null>('unset');

  const isExternal = navigation.scope === 'quest_external';
  const isOnboardingScope = navigation.scope === 'onboarding';

  // setting the global whitelabel logo
  useEffect(() => {
    const principalCompany = user?.companies?.find((company) => company.isPrincipal === true);
    if (user && principalCompany?.features.includes('Whitelabel')) {
      if (principalCompany?.whitelabelConfig) {
        setLogoB64(principalCompany.whitelabelConfig.logoB64);
      }
    } else {
      if (tokenInfo?.whitelabelConfigJson) {
        const config = JSON.parse(tokenInfo.whitelabelConfigJson);
        if (config.b64) {
          setLogoB64(config.b64);
        }
      } else {
        setLogoB64(null);
      }
    }
  }, [user, tokenInfo?.whitelabelConfigJson]);

  const handleLogoClick = () => {
    !isExternal && !isOnboardingScope && clickable && history.push('/');
  };

  if (logoB64 === 'unset' || loading) {
    return null;
  }

  return (
    <div className={cx(clickable && 'cursor-pointer')}>
      {!logoB64 ? (
        <img className={cx('max-w-none')} src="/assets/images/dieter_logo.svg" alt="Dieter" onClick={handleLogoClick} />
      ) : (
        <img className="max-w-none" src={logoB64} alt="Custom Logo" onClick={handleLogoClick} />
      )}
    </div>
  );
}
