// queries.js
import { gql, useMutation } from '@apollo/client';
import { NavigationScope } from '@app/context/NavigationContext';
import { ChatMessage } from '@app/hooks/types';

export const CHAT_FIELDS = gql`
  fragment ChatFields on Chat {
    id
    createdAt
    updatedAt
    messages {
      role
      content
    }
  }
`;

// Define the mutation
export const INITIATE_CHAT_MUTATION = gql`
  ${CHAT_FIELDS}
  mutation InitiateChat($applicationId: String) {
    initiateChat(applicationId: $applicationId) {
      ...ChatFields
    }
  }
`;

// Define the response and variables interfaces
interface InitiateChatMutationResult {
  initiateChat: Chat;
}

export interface Chat {
  id: string;
  createdAt: string;
  updatedAt: string;
  messages: ChatMessage[];
  length: number;
  scope: NavigationScope;
}

export interface InitiateChatMutationVariables {
  // optionally pass applicationId, currently only relevant for agent
  applicationId?: string;
}

// Custom hook to use the mutation
export const useInitiateChatMutation = () => {
  return useMutation<InitiateChatMutationResult, InitiateChatMutationVariables>(INITIATE_CHAT_MUTATION, {
    refetchQueries: ['GetUser'],
  });
};
