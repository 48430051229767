import { Topics } from '@app/context/constants';
import { IntervalValues, ModeValues, ProductValues, TopicValues, UpgradeProps } from '@app/routes/Pricing/Pricing';
import { User } from '@dieterApi/user/useUserQuery';
import { Divider } from '@mui/material';
import cx from 'classnames';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Button } from '../Button/Button';
import { PricingProduct, TenantVariant, TicketProduct } from './PricingTable';
import ProductsSelect from './ProductsSelect';
import TenantsSelect from './TenantsSelect';
import { useLocalizedTranslation } from './Translation';
import { UserCentricsFeature } from './UserCentricsFeature';

interface ProductProps {
  product: PricingProduct;
  user?: User;
  mode: ModeValues;
  interval: IntervalValues;
  queryOption?: string | null;
  showButton?: boolean;
  selectedProduct?: TicketProduct;
  onProductSelect?(val: TicketProduct | undefined): void;
  selectedTenants?: TenantVariant;
  onTenantSelect?(val: TenantVariant): void;
  locale: string;
  isWixUser?: boolean;
  onUpgrade?(props: UpgradeProps): void;
  isUpgradeHighlight?: boolean;
}

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

const topicToTicketProduct: PartialRecord<Topics, TopicValues> = {
  'topic-dse': 'dse',
  'topic-toms': 'toms',
  'topic-vvz': 'vvt',
  'topic-av': 'avv',
};

const USERCENTRICS_PRICE = {
  month: 8,
  year: 5,
};

export function ProductCardV2({
  product,
  user,
  interval,
  mode,
  queryOption,
  selectedProduct,
  onProductSelect,
  selectedTenants,
  onTenantSelect,
  locale,
  showButton = true,
  isWixUser = false,
  onUpgrade,
  isUpgradeHighlight = false,
}: ProductProps) {
  const { t: tl } = useLocalizedTranslation(locale);
  const {
    name,
    value,
    free,
    description,
    price,
    tenantPrice,
    featureTitle,
    features,
    preferred,
    withTest,
    pricingLink,
    selectableProducts,
  } = product;

  const [isHovered, setIsHovered] = useState(false);
  const [withUsercentrics, setWithUsercentrics] = useState(false); // !product.isAgency

  const isHighlighted = !!queryOption && queryOption === value;

  const isDpoProduct = mode === 'dpos';

  const moveUp = useSpring({
    transform: isHovered && !isHighlighted ? 'translateY(-5px)' : 'translateY(0px)',
    boxShadow: preferred ? '0 2px 17px 1px rgb(224 193 241)' : '0 3px 17px 1px rgb(245 236 251)',
  });

  const fadeIn = useSpring({
    opacity: interval === 'year' ? 1 : 0,
  });

  let displayPrice = free ? undefined : price || selectedProduct?.price;
  // when usercentrics is selected, add the price to the display price
  displayPrice =
    withUsercentrics && displayPrice
      ? {
          ...displayPrice,
          year: displayPrice?.year + USERCENTRICS_PRICE.year,
          month: displayPrice?.month + USERCENTRICS_PRICE.month,
        }
      : displayPrice;

  // when dpo then add the price for the tenant package
  displayPrice =
    isDpoProduct && selectedTenants && displayPrice
      ? {
          ...displayPrice,
          year: displayPrice?.year + selectedTenants * (tenantPrice?.[selectedTenants]?.year || 0),
          month: displayPrice?.month + selectedTenants * (tenantPrice?.[selectedTenants]?.month || 0),
        }
      : displayPrice;

  const springProps = useSpring({
    val: interval !== 'month' ? displayPrice?.year : displayPrice?.month,
    from: { val: interval !== 'month' ? displayPrice?.month : displayPrice?.year },
  });

  const handleClick = () => {
    // push custom event to GA4
    if (window.dataLayer && displayPrice) {
      window.dataLayer.push({
        event: 'ga4-event-order-checkout',
        // we measure only 5% of the ordervalue in the checkout conversion
        // so we can attribute some of the value to the funnel
        ordervalue: displayPrice[interval] * 12 * 0.1,
        originalvalue: displayPrice[interval] * 12,
        email: user?.email,
      });
    }
    // push event to matomo
    if (window._paq && displayPrice) {
      window._paq.push(['trackEvent', 'funnel', 'checkout', 'checkout ' + value]);
    }
    // push event to tracify
    if (window.tracify) {
      window.tracify.captureAddToCart();
    }

    if (isSubscriber) {
      onUpgrade && onUpgrade({ product: value as ProductValues, topic: selectedProduct?.value, mode, interval });
    } else {
      window.open(pricingLink[interval] + (withUsercentrics ? '&usercentrics=true' : ''), '_blank');
    }
  };

  const handleProductSelect = (val: string) => {
    onProductSelect && onProductSelect(selectableProducts?.find((opt) => opt.value === val));
  };

  const handleTenantSelect = (val: TenantVariant) => {
    onTenantSelect && onTenantSelect(val);
  };

  const handleUsercentricsCheck = (val: boolean) => {
    setWithUsercentrics(val);
  };

  const principalCompany = user?.companies.find((company) => company.isPrincipal);
  let isCurrentSubscription = false;
  let productIncluded = false;
  const isDpo = principalCompany?.isDpo || false;
  const bookedProducts =
    principalCompany?.topicsActivated.map((topic) => topicToTicketProduct[topic] as TopicValues) || [];
  const isAgency = principalCompany?.isAgency || false;
  const isPlus = principalCompany?.isPlus || false;
  const isSubscriber = user?.isSubscriber || false;
  const isTicket = (principalCompany?.subscription === 'Ticket' && isSubscriber) || false;
  const isBasic = (principalCompany?.subscription === 'Basic' && isSubscriber) || false;
  const isPremium = (principalCompany?.subscription === 'Premium' && isSubscriber && !isPlus) || false;
  const isBasicOrPremium = isBasic || isPremium || isPlus;
  let isCurrentTicket = false;
  let isUpgradable = true;

  // these cases cover the different scenarios for the product card
  // depending on the user's subscription and the product's properties
  switch (product.value) {
    case 'basic':
      switch (product.isAgency) {
        case true:
          productIncluded = isPremium && isAgency;
          isCurrentSubscription = isBasic && isAgency && !isDpo;
          isUpgradable = !isDpo;
          break;
        case false:
          switch (product.isDpo) {
            case true:
              isCurrentSubscription = isDpo && isSubscriber;
              isUpgradable = !isAgency;

              break;
            case false:
              productIncluded = ((isPremium || isPlus) && !isAgency) || isAgency;
              isCurrentSubscription = isBasic && !isAgency && !isDpo;
              break;
          }

          break;
      }
      break;
    case 'premium':
      switch (product.isAgency) {
        case true:
          isCurrentSubscription = isPremium && isAgency && !isDpo;
          isUpgradable = !isDpo;
          break;
        case false:
          productIncluded = isDpo || isAgency || isPlus;
          isCurrentSubscription = isPremium && !isAgency && !isDpo;
          break;
      }
      break;
    case 'premiumplus':
      productIncluded = isPlus;
      isCurrentSubscription = isPlus;
      break;
    case 'ticket':
      productIncluded = isBasicOrPremium;
      isCurrentTicket = bookedProducts.includes(selectedProduct?.value as TopicValues);
      isCurrentSubscription = isTicket && isCurrentTicket;

      break;
  }

  return (
    <div className="flex flex-1 flex-col items-center sm:max-w-[350px] min-w-[310px]">
      {isSubscriber ? (
        <div className="h-9 block font-bold text-primary-root">
          {isCurrentSubscription
            ? tl('common.currentplan', 'Dein aktueller Plan')
            : isUpgradeHighlight && !productIncluded
              ? tl('common.upgradeoption', 'Deine Upgrade-Option')
              : isTicket &&
                product.value === 'basic' &&
                mode === 'companies' &&
                tl('common.recommendation', 'Unsere Empfehlung')}
        </div>
      ) : (
        <div className="h-9 block font-bold text-gray-400">{preferred && 'Beliebteste Option'}</div>
      )}
      {/* Animated Container with Hover Effect */}
      <animated.div
        style={moveUp}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cx(
          'flex flex-col gap-5 px-6 pb-4 rounded-xl border-2 border-gray-300 h-full relative',
          isCurrentSubscription ? 'bg-primary-100' : 'bg-white',
          {
            'border-4 border-primary-root':
              (isHighlighted && !productIncluded && !isCurrentSubscription) || isCurrentSubscription,
          }
          // preferred ? 'shadow-xl' : 'shadow-sm'
        )}
        // style={{
        //   boxShadow: preferred ? '0 2px 17px 1px rgb(224 193 241)' : '0 3px 5px 1px rgb(224 193 241)',
        // }}
      >
        {/* Blurry Backdrop when not upgradable */}
        {((productIncluded && !isCurrentSubscription) || !isUpgradable) && isSubscriber && (
          <div className="absolute w-full h-full bg-gray-300/30 z-50 top-0 left-0 rounded-md backdrop-blur-[1px]" />
        )}
        <div>
          <div className="flex gap-5 items-center mb-5">
            <div className="flex-1">
              <div className="text-xl border-b-2 border-x-2 p-2 border-gray-300 rounded-b-lg">
                <span className="block">Dieter</span>
                <span className="block font-semibold text-primary-root">{name}</span>
              </div>
            </div>

            {displayPrice && (
              <animated.div
                style={fadeIn}
                className={cx('rounded-md flex flex-col items-center bg-secondary-root h-fit')}
              >
                <span className="text-base">{tl('common.save')}</span>
                <div className="bg-primary-root m-1 text-2xl font-medium text-center rounded-lg w-fit px-1 text-white">
                  {displayPrice.discount}%
                </div>
              </animated.div>
            )}
          </div>
          <div>
            <div className="md:h-16 w-full overflow-visible bg-transparent">
              {!!selectableProducts?.length && (
                <ProductsSelect options={selectableProducts} value={selectedProduct} onSelect={handleProductSelect} />
              )}
              {isDpoProduct && <TenantsSelect value={selectedTenants} onSelect={handleTenantSelect} locale={locale} />}
            </div>
            {displayPrice ? (
              <div>
                <div className="flex justify-between">
                  <div className="text-primary-root">
                    <div className="inline">
                      <animated.div className="text-5xl font-medium inline rounded-md">
                        {springProps?.val?.to((val) => (isWixUser ? val.toFixed(2) : Math.round(val)))}
                      </animated.div>
                      <span className="text-5xl font-light">€</span>
                    </div>
                    <span className="text-black text-xs">/ {tl('common.month')}</span>
                  </div>
                </div>

                <div className={cx('line-through', interval === 'month' && 'text-white')}>
                  {tl('common.instead')}: <span className="font-semibold">{displayPrice.month} €</span>
                </div>
                {/* // format as badge */}

                <div className="text-xs bg-secondary-200 rounded-lg py-1 px-2 w-fit">{tl('common.period')}</div>
              </div>
            ) : (
              <div className="h-24 flex items-center text-4xl font-medium text-primary-root">
                <span className="">{tl('common.always_free')}</span>
              </div>
            )}
          </div>
        </div>
        <div className={cx('text-gray-500 text-sm ', product.isAgency ? 'md:min-h-[200px]' : 'md:min-h-[120px]')}>
          {selectableProducts ? selectedProduct?.description : description}{' '}
        </div>
        {!free &&
          !product.isAgency &&
          !isWixUser &&
          !productIncluded &&
          !isCurrentSubscription &&
          !isSubscriber &&
          false && (
            <UserCentricsFeature
              onCheck={handleUsercentricsCheck}
              checked={withUsercentrics}
              interval={interval}
              locale={locale}
            />
          )}

        {showButton &&
        ((isUpgradable && !productIncluded && (!isCurrentSubscription || (isTicket && !isCurrentTicket))) ||
          !isSubscriber) ? (
          <Button
            onClick={handleClick}
            googleTriggerClassName={displayPrice && 'ga4-event-landing-checkout'}
            disabled={!!selectableProducts?.length && !selectedProduct}
          >
            {isSubscriber
              ? tl('common.upgrade_now')
              : !displayPrice
                ? tl('common.start_now')
                : withTest
                  ? tl('common.try_now')
                  : tl('common.book_now')}
          </Button>
        ) : (
          <div className="h-[42px]"></div>
        )}
        <Divider />
        <div className="flex flex-col gap-2 min-h-[265px]">
          <span className="text-gray-500">{featureTitle}</span>

          {selectableProducts
            ? selectedProduct?.features
                .slice(0, 5)
                .map((feature, idx) => <ProductFeature key={idx} feature={feature} />)
            : features?.slice(0, 5).map((feature, idx) => <ProductFeature key={idx} feature={feature} />)}
        </div>

        <div>
          {/* on button click change the hash component of url to #datenschutzmanagement-und-prozesse to url */}
          <button
            className="text-primary-root"
            onClick={() => {
              window.location.hash = '';
              window.location.hash = '#datenschutzmanagement-und-prozesse';
            }}
          >
            Weitere Features...
          </button>
        </div>
      </animated.div>
    </div>
  );
}

function ProductFeature({ feature }: { feature: string }) {
  return (
    <div className="flex gap-2 items-center h-[40px]">
      <div className="flex-none text-gray-600 w-[30px] h-[30px] flex items-center text-lg justify-center font-bold">
        ✓
      </div>
      <span className="text-sm">{feature}</span>
    </div>
  );
}
