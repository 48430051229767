import { useUser } from '@app/context/UserContext';
import { useUpdateUtmParamsMutation } from '@dieterApi/company/useCompanyUpdateUtmParamsMutation';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function ConversionTracker() {
  const location = useLocation();
  const { user } = useUser();
  const history = useHistory();
  const [conversionTracked, setConversionTracked] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const ordervalue = queryParams.get('ordervalue');
  const session_id = queryParams.get('session_id');
  const items = queryParams.get('items');

  const [updateUtmParams] = useUpdateUtmParamsMutation();

  // push history change to google analytics
  useEffect(() => {
    return history.listen((location) => {
      // Ensure the GTM function exists
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'app_route', // the event name
          pageTitle: document.title, // set page title
          pagePath: location.pathname, // set page path
          pageLocation: window.location.href, // set complete url
        });
      }
    });
  }, [history]);

  // send utm parameters to backend for future conversion tracking
  useEffect(() => {
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      .map((param) => {
        const value = queryParams.get(param);
        return value ? `${param}=${encodeURIComponent(value)}` : null;
      })
      .filter(Boolean)
      .join('&');

    if (utmParams) {
      updateUtmParams({
        variables: {
          utmParams,
        },
      }).catch((error) => {
        console.error('Error updating UTM params:', error);
      });
    }
  }, [location.search, updateUtmParams]);

  useEffect(() => {
    if (!ordervalue || !user || !session_id || conversionTracked) return;
    // Convert ordervalue to integer
    const value = parseInt(ordervalue || '0', 10);
    let ga4items = [];
    try {
      ga4items = JSON.parse(items || '[]');
    } catch (e) {
      console.error('Error parsing items: ', items);
    }

    // Function to push the custom event to GA4
    const pushToDataLayer = () => {
      if (window.dataLayer && value) {
        window.dataLayer.push({
          event: 'ga4-event-order-success',
          ordervalue: value / 100,
          email: user?.email,
          items: ga4items,
          session_id,
          item_id: ga4items?.[0]?.item_id,
        });
      }
    };

    const pushToMatomo = () => {
      if (window._paq && value) {
        window._paq.push(['trackEvent', 'funnel', 'order success', 'order success', value / 100]);
      }
    };

    const pushToTracify = () => {
      if (window.tracify && value) {
        console.log('Capturing purchase event with Tracify');
        window.tracify.capturePurchase({
          amount: (value / 100).toFixed(2),
          // create random uuid
          orderId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          currency: 'EUR',
        });
      }
    };

    // Retry every second until dataLayer is found
    const intervalId = setInterval(() => {
      if (window.dataLayer) {
        pushToDataLayer();
        clearInterval(intervalId); // Clear the interval once the dataLayer is found and event is pushed
      }
    }, 1000);

    // Retry every second until _paq is found
    const intervalIdMatomo = setInterval(() => {
      if (window._paq) {
        pushToMatomo();
        clearInterval(intervalIdMatomo); // Clear the interval once the dataLayer is found and event is pushed
      }
    }, 1000);

    // Retry every second until tracify is found
    const intervalIdTracify = setInterval(() => {
      if (window.tracify) {
        pushToTracify();
        clearInterval(intervalIdTracify); // Clear the interval once the dataLayer is found and event is pushed
      }
    }, 1000);

    setConversionTracked(true); // Mark as tracked
  }, [ordervalue, user, session_id]); // Dependency array to rerun the effect if ordervalue changes

  return null;
}
