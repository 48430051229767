import { gql, useMutation } from '@apollo/client';

import { QUESTIONNAIRE_FIELDS, UserQuestionnaire } from '@dieterApi/user/useUserQuery';

const CREATE_QUESTIONNAIRE = gql`
  ${QUESTIONNAIRE_FIELDS}
  mutation CreateQuestionnaire($appId: String!, $userValueIndex: String, $token: String, $onlyIfNotExists: Boolean) {
    createQuestionnaire(
      appId: $appId
      userValueIndex: $userValueIndex
      token: $token
      onlyIfNotExists: $onlyIfNotExists
    ) {
      ...UserQuestionnaireFields
      application {
        id
      }
    }
  }
`;

export interface CreateQuestionnaireInput {
  appId: string;
  userValueIndex?: string;
  token?: string;
  onlyIfNotExists?: boolean;
}

export interface CreateQuestionnaireResult {
  createQuestionnaire: UserQuestionnaire & {
    application: {
      id: string;
    };
  };
}

export function useCreateQuestionnaireMutation() {
  return useMutation<CreateQuestionnaireResult, CreateQuestionnaireInput>(CREATE_QUESTIONNAIRE, {
    refetchQueries: ['GetUser'],
  });
}
