import { DocumentShareToken, parseJwt } from '@app/utils/parseJwt';
import { useGetShareTokenJWTQuery } from '@dieterApi/sharetoken/useGetShareTokenJWTQuery';
import { useMemo } from 'react';
import { generatePath as routerGeneratePath, useHistory, useLocation, useRouteMatch } from 'react-router';

export const fullPath = '/docs/:questionnaireId?/:questionId?';

export interface RouteParams {
  questionnaireId?: string;
  questionId?: string;
}

export interface RouteParamsWithSection extends RouteParams {
  section?: number;
  revisit?: boolean;
  token?: string;
  name?: string;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useQuestionaireRoute() {
  const history = useHistory();
  const query = useQueryParams();
  const match = useRouteMatch<RouteParams>(fullPath);
  const currentParams: RouteParamsWithSection = Object.assign(
    {
      questionnaireId: undefined,
      questionId: undefined,
      section: undefined,
      revisit: undefined,
      token: undefined,
      name: undefined,
    },
    {
      ...match?.params,
      section: parseInt(query.get('section') || ''),
      revisit: query.get('revisit') === 'true' || false,
      token: query.get('token') || '',
      name: query.get('name') || '',
    }
  );

  const { data: shareTokenJWT } = useGetShareTokenJWTQuery({
    variables: { id: currentParams.token! },
  });

  const jwt = shareTokenJWT?.getShareTokenJWT?.jwt;

  const tokenInfo = jwt ? (parseJwt(jwt) as DocumentShareToken) : undefined;

  function generatePath({
    questionnaireId = currentParams.questionnaireId,
    questionId = currentParams.questionId,
    section = currentParams.section,
    revisit = currentParams.revisit,
    token = currentParams.token,
    name = currentParams.name,
  }: RouteParamsWithSection) {
    const queryParam =
      `?revisit=${revisit}` +
      (section ? `&section=${section}` : '') +
      (token ? `&token=${token}` : '') +
      (name ? `&name=${name}` : '');
    return (
      routerGeneratePath(fullPath, {
        questionnaireId: questionnaireId,
        questionId: questionId,
      }) + queryParam
    );
  }

  function navigateToPath(parameters: RouteParamsWithSection) {
    const path = generatePath(parameters);
    history.push(path);
  }

  return {
    generatePath,
    navigateToPath,
    currentParams,
    jwt,
    tokenInfo,
  };
}
