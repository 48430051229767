import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { Icon } from '@components/ui';

import { Badge, Collapse, Tooltip } from '@mui/material';
import cx from 'classnames';
import { forwardRef, useState } from 'react';
import './route-dashboard.sass';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  topic: UserTopicApplicationsQuestionnaires;
  status: ApplicationStatus;
  // onClick: (appTitle: string) => void;
  isHeader?: boolean;
  appId?: string;
  scrollOnClick?: () => void;
  showStatusColor?: boolean;
}

export const SectionItem = forwardRef<HTMLDivElement, Props>(function X(
  { topic, status, isHeader = false, scrollOnClick, showStatusColor = true, ...props },
  ref
) {
  const {
    navigation: { activeTopic, activeSubSection, newDocuments, activeSection },
    setNavigation,
    navigateDashboard,
  } = useNavigation();
  const { user } = useUser();
  const tasks = useTasks();
  const [hover, setHover] = useState<boolean>(false);

  // TODO: This is hardcoded since we only have one application for the SubSection yet.
  const subItems = topic.subItemLocales || [];

  const handleClick = () => {
    setNavigation((nav) => {
      nav.itemListOpen = !nav.isMobile;
    });
    navigateDashboard(isHeader ? NavigationSection.Overview : NavigationSection.Topic, topic.id);
    scrollOnClick && scrollOnClick();
  };

  const handleSubItemClick = (subItem: NavigationSubsection) => {
    setNavigation((nav) => {
      nav.itemListOpen = !nav.isMobile;
    });
    navigateDashboard(NavigationSection.Topic, topic.id, subItem);
    scrollOnClick && scrollOnClick();
  };

  const sectionDocuments = user?.questionnaires?.filter((q) => q.application.topic.id === topic.id);
  const sectionTasks = tasks && tasks.filter((task) => task.topic.id === topic.id);
  const newTasks = sectionTasks && sectionTasks.filter((task) => task.isNew && !task.done).length > 0;
  // const newDocs = sectionDocuments?.map((q) => newDocuments[q.id]?.some(Boolean)).some(Boolean);
  const taskAvailable = Boolean(sectionTasks?.length);
  const isActive =
    (activeTopic === topic.id && !activeSubSection) ||
    // We can use topic title here, because for the overview section this component is called with
    // a dummy topic with fixed title, which will never be displayed anywhere
    (topic.title === (NavigationSection.Overview as unknown as Topics) && activeSection === NavigationSection.Overview);
  const notAvailable = topic.applications.length === 0;
  const sectionStarted = true || (sectionDocuments?.length || 0) > 0;

  const isDisabled = status === ApplicationStatus.DISABLED;

  const withSubItems = subItems.length > 0 && !topic.tags.map((tag) => tag.tag).includes('nosubitems');

  return (
    <div className="flex flex-col" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} {...props}>
      <div
        className={cx(
          { dtRouteDashboard__sectionItemHeader: isHeader },
          'text-base text-gray-500 py-4 pl-4 flex items-center justify-between',
          {
            sectionActive: isActive,
            sectionEnabled: true,
          }
        )}
        data-testid={`section-button-${topic.id}`}
        onClick={(e) => handleClick()}
        ref={ref}
      >
        <div className="flex items-center gap-10">
          {isDisabled && false ? (
            <Icon type="lock-24" />
          ) : (
            !isHeader && (
              <Badge color="error" variant={newTasks && !isDisabled ? 'dot' : undefined}>
                <StatusIcon
                  status={status}
                  hasFiles={topic.hasFiles}
                  shouldHaveFiles={topic.shouldHaveFiles}
                  notAvailable={notAvailable}
                  showStatusColor={showStatusColor}
                />
                {/* <span className={cx('dot', status)} /> */}
              </Badge>
            )
          )}
          <span
            className={cx(
              {
                pointer: true,

                notAvailable,
                'text-gray-400': isDisabled,
              },
              'flex-1',
              'pr-3'
            )}
          >
            {topic.title}
          </span>
        </div>
        {isActive && <Icon type="chevron-right-16" />}
      </div>
      {withSubItems &&
        subItems.map((subItem) => {
          const subSectionActive = (activeSubSection && activeSubSection === subItem.id) || false;

          // we have a special condition on the subitem "Deine Subunternehmen": It should only be shown
          // if the user has marked a partner company as "Auftraggeber" and has at least one marked as "Auftragnehmer"
          // this corresponds to user.userValues: {key: "DATA_PROCESSOR_DIRECTION", value: "Ich bin der Auftragnehmer" | "Ich bin der Auftraggeber"}
          let showSubItem = true;
          if (subItem.id === NavigationSubsection.SubContracts) {
            const isSubContractor =
              user?.userValues?.some(
                (userValue) =>
                  userValue.key === 'DATA_PROCESSOR_DIRECTION' && userValue.value === ContractDirection.Contractor
              ) || false;
            const hasSubContractors =
              user?.userValues?.some(
                (userValue) =>
                  userValue.key === 'DATA_PROCESSOR_DIRECTION' && userValue.value === ContractDirection.Client
              ) || false;
            showSubItem = isSubContractor && hasSubContractors;
          }

          if (subItem.id === NavigationSubsection.Usercentrics && !user?.hasFeature('Usercentrics')) {
            showSubItem = false;
          }

          if (subItem.id === NavigationSubsection.TechnologyScan) {
            showSubItem = false;
          }

          // we have a special condition on the subitem "Usercentrics": It should only be shown
          // if the user has feature "Usercentrics" activated
          // REMOVED: Usercentrics is now always shown and we show a teaser to book it
          // if (subItem.id === NavigationSubsection.Usercentrics) {
          //   showSubItem = user?.hasFeature('Usercentrics') || false;
          // }

          return (
            <Collapse
              in={(isActive || subSectionActive || hover) && (sectionStarted || user?.experimental) && showSubItem}
              key={subItem.id}
            >
              <div
                className={cx('dtRouteDashboard__subItem', { sectionActive: subSectionActive, subSectionActive })}
                onClick={() => handleSubItemClick(subItem.id)}
              >
                {subItem.title}
              </div>
            </Collapse>
          );
        })}
    </div>
  );
});

import { NavigationSection, NavigationSubsection, Topics } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { ApplicationStatus, UserTopicApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useTranslation } from 'react-i18next';
import { ContractDirection } from './Topic/SubSections/PartnerListEntry/typesAndEnums';

interface StatusIconProps {
  status?: ApplicationStatus;
  hasFiles?: boolean;
  shouldHaveFiles?: boolean;
  notAvailable?: boolean;
  showStatusColor?: boolean;
}

export function StatusIcon({ status, hasFiles, shouldHaveFiles, notAvailable, showStatusColor }: StatusIconProps) {
  const { t } = useTranslation();
  // gelb plus ! = hat Dokumente hochgeladen
  // rot plus ? = hat angegeben Dokumente wären vorhanden, allerdings keine hochgeladen
  // nur rot = hat angegeben es existiert nichts zu diesem thema
  // grün mit Haken = alle abgeschlossen
  // grüner Kreis = ist aktuell in der Bearbeitung
  let icon: JSX.Element | null = null;
  let statusColor = 'inprogress';
  let tooltip = '';

  if (status === ApplicationStatus.PENDING) {
    statusColor = 'warning';
    tooltip = 'Schalte diesen Bereich jetzt frei um dein Dokument zu verwenden.';
  }

  if (status === ApplicationStatus.DISABLED) {
    icon = <CloseIcon />;
    statusColor = 'danger';
    tooltip = t(
      'route.dashboard.status-tooltip.has-no-docs',
      'Du hast bisher noch keine Dokumente zu diesem Themengebiet.'
    );

    if (shouldHaveFiles && hasFiles) {
      icon = <PriorityHighIcon />;
      statusColor = 'warning';
      tooltip = t(
        'route.dashboard.status-tooltip.processing-uploaded-docs',
        'Du hast Dokumente hochgeladen. Diese müssen regelmäßig überprüft werden.'
      );
    }
    if (shouldHaveFiles && !hasFiles) {
      icon = <QuestionMarkIcon />;
      statusColor = 'danger';
      tooltip = t(
        'route.dashboard.status-tooltip.uploaded-docs-not-saved',
        'Du hast angegeben, es gibt Dokumente zu diesem Themengebiet. Diese hast du allerdings noch nicht hinterlegt.'
      );
    }

    if (!showStatusColor) {
      statusColor = 'notrated';
      icon = null;
      tooltip = '';
    }
  }

  if (notAvailable) {
    statusColor = 'notavailable';
  }

  if (status === ApplicationStatus.COMPLETED) {
    icon = <CheckIcon />;
    statusColor = 'success';
    tooltip = '';
  }
  if (status === ApplicationStatus.IN_PROGRESS) {
    icon = null;
    statusColor = 'inprogress';
    tooltip = '';
  }

  return (
    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={tooltip}>
      <div className={cx('dtStatusIcon', statusColor)}>{icon}</div>
    </Tooltip>
  );
}
