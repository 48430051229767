import i18n from 'i18next';

export function useLocalizedTranslation(locale: string) {
  const localizedI18n = i18n.createInstance();
  localizedI18n.init({
    resources: {
      en: {
        translation: {
          usercentrics: '<0>Additional option:</0> Professional Cookie Banner plus {{price}} EUR/Month',
          customer_mode: {
            companies: 'For Companies',
            agencies: 'For Agencies',
            dpos: 'For Data Protection Officers',
          },
          customer_mode_short: {
            companies: 'Companies',
            agencies: 'Agencies',
            dpos: 'DPOs',
          },
          common: {
            currentplan: 'Your Current Plan',
            upgradeoption: 'Your Upgrade Option',
            recommendation: 'Our Recommendation',
            upgrade_now: 'Upgrade Now',
            book_now: 'Book Now',
            try_now: 'Try 7 Days for Free',
            start_now: 'Start Immediately',
            month: 'Month',
            period: 'Contract Duration 1 Year',
            save: 'Save',
            monthly_billing: 'Monthly Billing',
            yearly_billing: 'Annual Billing (save up to 50%)',
            instead: 'Instead of',
            always_free: 'Always Free',
          },
          freemium: {
            description: 'The easy entry into data protection',
            f1: 'Comprehensive GDPR analysis of the company',
            f2: 'Legally secure management of existing documents',
            f3: 'General advice and recommendations for implementing GDPR',
            f4: 'Data protection legal training for one user',
            f5: 'Limited use of the data protection management system',
          },
          ticket: {
            name: 'Single',
            description: 'Book an individual service from the toolkit',
            featureTitle: 'Everything from the Free Plan plus:',
            dse: {
              label: 'Website & Social Media',
              description:
                'Legally secure website and social media profiles through precise privacy statements and integrated legal notice creation',
              f1: 'GDPR-compliant privacy statement',
              f2: 'Direct integration into the website via live API',
              f3: 'Legally secure legal notice',
              f4: 'Ideal also for webshops, blogs, and social media appearances',
              f5: 'Suitable passages for ALL tracking or analysis tools',
              f6: 'Legal team updates passages for new services within a week',
              f7: 'Automated notices and action recommendations on legal changes',
            },
            toms: {
              description: 'Simple and intuitive documentation of all actions for your company',
              label: 'TOMs & Authorization Concept',
              f1: 'GDPR-compliant documentation of all actions',
              f2: 'Simple creation of the authorization concept',
              f3: 'Automated notices on legal changes',
            },
            vvt: {
              description: 'Combined creation of Record of Processing Activities (ROPA) and deletion concept',
              label: 'Record of Processing Activities & Deletion Concept',
              f1: 'Creation of the Record of Processing Activities (ROPA)',
              f2: 'Creation of the deletion concept',
              f3: 'Automated notices on legal changes',
            },
            avv: {
              description: 'Efficient creation and management of data processing agreements',
              label: 'Data Processing (AVV)',
              f1: 'Creation of DP agreements',
              f2: 'Generation of Standard Contractual Clauses (SCCs)',
              f3: 'Creation of Joint-Controlling Agreements',
              f4: 'Legally secure adjustment to current regulations',
              f5: 'Easy management of agreements',
            },
          },
          basic: {
            description:
              'The complete data protection toolkit for all companies that do not require an officially appointed data protection officer as per GDPR requirements.',
            featureTitle: 'Everything from the Free Plan plus:',
            f1: 'Use of the data protection management system',
            f2: 'Creation of all GDPR documents',
            f3: '1 User included',
            f4: 'GDPR-compliant privacy statement and legal notice',
            f5: 'Establishment of necessary processes',
            f6: 'Data protection basic training for up to 10 employees',
            f7: 'Legally secure response to data subject requests',
            f8: 'GDPR-compliant handling of data breaches',
            f9: 'Online data protection (Website, Social Media, etc.)',
            f10: 'Offline data protection (internal data processing, communication, etc.)',
            f11: 'Employee data protection (obligations, home office, recruitment & employment, etc.)',
            f12: 'Contract partner (instructions, rights, etc.)',
            f13: 'Data processing (AVV)',
            f14: 'Technical and organizational measures (TOMs)',
            f15: 'Record of Processing Activities & Deletion Concept',
            f16: 'Video surveillance',
            f17: 'Data protection impact assessment',
            f18: '2 Website Scans',
          },
          agency_basic: {
            description:
              'The comprehensive data protection complete package for agencies that want to ensure their own GDPR compliance and also create GDPR-compliant privacy statements and legal notices for up to 50 clients.',
            featureTitle: 'Everything from Basic for Companies - plus:',
            f1: 'Client management in the dashboard',
            f2: '50 individual privacy statements and legal notices',
            f3: 'Consent-Banner powered by Usercentrics for all clients',
            f4: 'Live service via chat',
            f5: 'GDPR website scanner',
            f6: 'Multi-user capability',
            f7: 'Possible detachment of client accounts',
          },
          premium: {
            description:
              'The data protection complete solution for all companies that are legally obligated to appoint a data protection officer (DPO).',
            featureTitle: 'Everything from Basic plus:',
            f1: 'Appointment of an official data protection officer (DPO)',
            f2: 'Monitoring of the implementation of necessary measures',
            f3: 'Communication with the responsible supervisory authority',
            f4: 'Instruction of the responsible persons in the company',
            f5: 'Basic training for up to 30 employees',
            f6: '3 Users included with role and right management',
            f7: '5 Website Scans',
          },
          premiumplus: {
            description: 'Our luxury package - for companies that place even more value on comprehensive support',
            featureTitle: 'Everything from Premium plus:',
            f1: 'Additional personal expert consultation',
            f2: 'Dedicated internal contact and prioritized case handling',
            f3: 'Basic training for up to 50 employees',
            f4: 'Unlimited number of users',
            f5: 'Role and right management',
          },
          agency_premium: {
            description: 'Our agency model including a data protection officer (DPO) for agencies',
            featureTitle: 'Everything from Agency Basic plus:',
            f1: 'Appointment of an official data protection officer (DPO)',
            f2: 'Monitoring of the implementation of necessary measures',
            f3: 'Communication with the responsible supervisory authority',
            f4: 'Instruction of the responsible persons in the company',
          },
          dpo_basic: {
            description: 'The comprehensive data protection complete package for data protection officers',
            featureTitle: 'Everything from Basic for Companies - plus:',
            f1: 'Client management in the dashboard',
            f2: 'GDPR-compliant work for you and your clients',
            f3: '1 client already included',
            f4: 'Additional clients can be added at any time',
            f5: 'Multi-user capability',
            f6: 'Live service via chat',
            f7: 'Possible detachment of client accounts for takeover by the client after project end',
          },
          tenants_select: {
            '0': '1 client included',
            '1': '+ 1 additional client',
            '5': '+ 5 additional clients',
            '10': '+ 10 additional clients',
          },
        },
      },

      de: {
        translation: {
          usercentrics: '<0>Zusatzoption:</0> Professioneller Cookie-Banner zzgl. {{price}} EUR/Monat',
          customer_mode: {
            companies: 'Für Firmen',
            agencies: 'Für Agenturen',
            dpos: 'Für Datenschutzbeauftragte',
          },
          customer_mode_short: {
            companies: 'Firmen',
            agencies: 'Agenturen',
            dpos: 'DSBs',
          },
          common: {
            currentplan: 'Dein aktueller Plan',
            upgradeoption: 'Deine Upgrade-Option',
            recommendation: 'Unsere Empfehlung',
            upgrade_now: 'Jetzt upgraden',
            book_now: 'Jetzt buchen',
            try_now: '7 Tage kostenlos testen',
            start_now: 'Sofort loslegen',
            month: 'Monat',
            period: 'Vertragslaufzeit 1 Jahr',
            save: 'Spare',
            monthly_billing: 'Monatliche Zahlweise',
            yearly_billing: 'Jährliche Zahlweise (spare bis zu 50%)',
            instead: 'Statt',
            always_free: 'Für immer kostenlos',
          },
          freemium: {
            description: 'Der einfache Einstieg in den Datenschutz',
            f1: 'Umfassende DSGVO-Analyse des Unternehmens',
            f2: 'Rechtssichere Verwaltung bestehender Dokumente',
            f3: 'Allgemeine Hinweise und Empfehlungen zur Umsetzung der DSGVO',
            f4: 'Datenschutzrechtliche Schulung eines Nutzers',
            f5: 'Eingeschränkte Nutzung des Datenschutz-Management-Systems',
          },
          ticket: {
            name: 'Einzelleistung',
            description: 'Buche eine Einzelleistung aus dem Baukasten',
            featureTitle: 'Alles aus dem Free Plan plus:',
            dse: {
              label: 'Website & Social Media',
              description:
                'Rechtssichere Website und Social-Media Profile durch passgenaue Datenschutzerklärung und integrierte Impressumserstellung',
              f1: 'DSGVO-konforme Datenschutzerklärung',
              f2: 'Direkte Integration in die Website per Live-API',
              f3: 'Rechtssicheres Impressum',
              f4: 'Ideal auch für Webshops, Blogs und Social Media Auftritte',
              f5: 'Passende Passagen für ALLE Tracking- oder Analyse-Tools',
              f6: 'Juristenteam ergänzt Passagen für neue Services innerhalb einer Woche',
              f7: 'Automatisierte Hinweise und Handlungsempfehlungen bei rechtlichen Veränderungen',
            },
            toms: {
              description: 'Einfache und intuitive Dokumentation aller Maßnahmen für dein Unternehmen',
              label: 'TOMs & Berechtigungskonzept',
              f1: 'DSGVO-konforme Dokumentation aller Maßnahmen',
              f2: 'Einfache Erstellung des Berechtigungskonzeptes',
              f3: 'Automatisierte Hinweise bei rechtlichen Veränderungen',
            },
            vvt: {
              description: 'Kombinierte Erstellung von Verzeichnis der Verarbeitungstätigkeiten (VVT) und Löschkonzept',
              label: 'Verarbeitungsverzeichnis & Löschkonzept',
              f1: 'Erstellung des Verzeichnisses der Verarbeitungstätigkeiten (VVT)',
              f2: 'Erstellung des Löschkonzeptes',
              f3: 'Automatisierte Hinweise bei rechtlichen Veränderungen',
            },
            avv: {
              description: 'Effiziente Erstellung und Verwaltung von Auftragsverarbeitungsverträgen',
              label: 'Auftragsverarbeitung (AVV)',
              f1: 'Erstellung von AV-Verträgen',
              f2: 'Generierung von Standardvertragsklauseln (SCCs)',
              f3: 'Erstellung von Joint-Controlling-Vereinbarungen',
              f4: 'Rechtssichere Anpassung an aktuelle Bestimmungen',
              f5: 'Einfaches Management von Verträgen',
            },
          },
          basic: {
            description:
              'Der komplette Datenschutz-Baukasten für alle Unternehmen, die keinen offiziell bestellten Datenschutzbeauftragten nach den Vorgaben der DSGVO benötigen. ',
            featureTitle: 'Alles aus dem Free Plan plus:',
            f1: 'Nutzung des Datenschutz-Management-Systems',
            f2: 'Erstellung aller DSGVO-Dokumente',
            f3: '1 User inklusive',
            f4: 'DSGVO-konforme Datenschutzerklärung und Impressum',
            f5: 'Etablierung der notwendigen Prozesse ',
            f6: 'Datenschutzrechtliche Grundschulung von bis zu 10 Mitarbeitern',
            f7: 'Rechtssichere Beantwortung von Betroffenenanfragen',
            f8: 'DSGVO-konformer Umgang mit Datenschutzverletzungen',
            f9: 'Online Datenschutz (Website, Social Media, etc.)',
            f10: 'Offline Datenschutz (interne Datenverarbeitung, Kommunikation, etc.)',
            f11: 'Mitarbeiter Datenschutz (Verpflichtungen, Home-Office, Bewerbung & Anstellung, etc.)',
            f12: 'Vertragspartner (Belehrungen, Rechte, etc.)',
            f13: 'Auftragsverarbeitung (AVV)',
            f14: 'Technische und organisatorische Maßnahmen (TOMs)',
            f15: 'Verarbeitungsverzeichnis & Löschkonzept',
            f16: 'Videoüberwachung',
            f17: 'Datenschutzfolgeabschätzung',
            f18: '2 Website Scans',
          },
          agency_basic: {
            description:
              'Das umfassende Datenschutz-Komplettpaket für Agenturen, die nicht nur ihre eigene DSGVO-Konformität sicherstellen, sondern auch für bis zu 50 Mandanten DSGVO-konforme Datenschutzerklärungen und Impressum erstellen möchten.',
            featureTitle: 'Alles aus Basic für Unternehmen - plus:',
            f1: 'Mandantenverwaltung im Dashboard',
            f2: '50 individuelle DSE und Impressum',
            f3: 'Consent-Banner powered by Usercentrics für alle Mandanten',
            f4: 'Live-Service per Chat',
            f5: 'DSGVO Website Scanner',
            f6: 'Multi-User Fähigkeit',
            f7: 'Mögliche Abkopplung von Mandantenaccounts',
          },
          premium: {
            description:
              'Die Datenschutz-Komplettlösung für alle Unternehmen, die gesetzlich verpflichtet sind einen Datenschutzbeauftragten (DSB) zu bestellen. ',
            featureTitle: 'Alles aus Basic plus:',
            f1: 'Bestellung eines offiziellen Datenschutzbeauftragten (DSB)',
            f2: 'Kontrolle der Umsetzung notwendiger Maßnahmen',
            f3: 'Kommunikation mit der zuständigen Aufsichtsbehörde',
            f4: 'Unterrichtung der verantw. Personen im Unternehmen',
            f5: 'Grundschulung von bis zu 30 Mitarbeitern',
            f6: '3 User inklusive Rollen- und Rechteverwaltung',
            f7: '5 Website-Scans',
          },
          premiumplus: {
            description: 'Unser Luxuspaket - für Unternehmen, die noch mehr Wert auf umfassende Betreuung legen',
            featureTitle: 'Alles aus Premium plus:',
            f1: 'Zusätzliche persönliche Experten-Beratung',
            f2: 'Fester interner Kontakt und priorisierte Fallbearbeitung',
            f3: 'Grundschulung von bis zu 50 Mitarbeitern',
            f4: 'Unbegrenzte Anzahl User',
            f5: 'Rollen- und Rechteverwaltung',
          },
          agency_premium: {
            description: 'Unser Agenturmodell inklusive Datenschutzbeauftragten (DSB) für Agenturen',
            featureTitle: 'Alles aus Agency Basic plus:',
            f1: 'Bestellung eines offiziellen Datenschutzbeauftragten (DSB)',
            f2: 'Kontrolle der Umsetzung notwendiger Maßnahmen',
            f3: 'Kommunikation mit der zuständigen Aufsichtsbehörde',
            f4: 'Unterrichtung der verantw. Personen im Unternehmen',
          },
          dpo_basic: {
            description: 'Das umfassende Datenschutz-Komplettpaket für Datenschutzbeauftragte',
            featureTitle: 'Alles aus Basic für Unternehmen - plus:',
            f1: 'Mandantenverwaltung im Dashboard',
            f2: 'Datenschutzkonformes Arbeiten für dich und für deine Kunden',
            f3: '1 Mandant bereits inklusive',
            f4: 'Zusätzliche Mandanten jederzeit zubuchbar',
            f5: 'Multi-User Fähigkeit',
            f6: 'Live-Service per Chat',
            f7: 'Mögliche Abkopplung der Mandantenaccounts zur Übernahme durch den Mandanten nach Projektende',
          },
          tenants_select: {
            '0': '1 Mandant inklusive',
            '1': '+ 1 zusätzlicher Mandant',
            '5': '+ 5 zusätzliche Mandanten',
            '10': '+ 10 zusätzliche Mandanten',
          },
        },
      },
    },
    lng: locale, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: 'de', // use en if detected lng is not available
  });

  return localizedI18n;
}
